.privacyAgreement {
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	background: #ffffff;
	color: #000;
	.name {
		text-align: center;
	}
	.content {
		text-indent: 2em;
		margin-top: 20px;
	}
	.title {
		margin-top: 20px;
	}
	.child {
		text-indent: 1em;
	}
}
