.ThirdPartyList {
  width: 100%;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  background: #ffffff;
  color: #000;
  .third-table {
    width: auto;
    border-collapse: collapse;
    .tableHeader {
      font-weight: 600;
      border: 1px solid black;
      word-break: break-all;
    }
    .tableCell {
      border: 1px solid black;
      text-align: center;
      word-break: break-all;
    }
    .type-style {
      // min-width: 100px;
    }
    .mode-style {
      // min-width: 80px;
    }
    a {
      color: #000;
    }
  }
}